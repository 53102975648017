import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_person = _resolveComponent("tm-person")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, { class: "reporting-helpdesk-priority-table" }, {
    "body-cell-agent-slot": _withCtx(({ row }) => [
      _createVNode(_component_tm_button, {
        "text-link": "",
        class: "blue-on-hover",
        to: { name: 'base.contacts.contactDetails', params: { id: '1' } }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_person, {
            name: row.person.fullName,
            "avatar-url": row.person.avatar,
            "avatar-color": row.person.avatarColor,
            "avatar-size": "small"
          }, null, 8, ["name", "avatar-url", "avatar-color"])
        ]),
        _: 2
      }, 1032, ["to"])
    ]),
    "body-cell-won-deals-slot": _withCtx(({ row }) => [
      _createTextVNode(_toDisplayString(row.wonDeals), 1)
    ]),
    "body-cell-won-deals-value-slot": _withCtx(({ row }) => [
      _createTextVNode(_toDisplayString(row.wonDeals > 0 ? _ctx.numberToPrice(Number(row.wonDealsValue * 1000)) : 0), 1)
    ]),
    "body-cell-lost-deals-slot": _withCtx(({ row }) => [
      _createTextVNode(_toDisplayString(row.lostDeals), 1)
    ]),
    "body-cell-lost-deals-value-slot": _withCtx(({ row }) => [
      _createTextVNode(_toDisplayString(row.lostDeals > 0 ? _ctx.numberToPrice(Number(row.lostDealsValue * 1000)) : 0), 1)
    ]),
    "body-cell-new-deals-slot": _withCtx(({ row }) => [
      _createTextVNode(_toDisplayString(row.newDeals), 1)
    ]),
    "body-cell-new-deals-value-slot": _withCtx(({ row }) => [
      _createTextVNode(_toDisplayString(row.newDeals > 0 ? _ctx.numberToPrice(Number(row.newDealsValue * 1000)) : 0), 1)
    ]),
    _: 1
  }))
}