import type { ReportCardType } from '@/definitions/shared/templates/types'

export const reportsDealsStats: ReportCardType[] = [
  {
    title: 'New deals',
    tooltip: 'Total number of deals created during the selected period.',
    counter: '248',
    percent: {
      value: '+12.8%',
      change: 'green',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '210',
  },
  {
    title: 'Deals won',
    tooltip: 'Total number of deals marked as Won during the selected period.',
    counter: '165',
    percent: {
      value: '+9.2%',
      change: 'green',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '145',
  },
  {
    title: 'Deals lost',
    tooltip: 'Total number of deals marked as Lost during the selected period.',
    counter: '34',
    percent: {
      value: '-14.2%',
      change: 'green',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '38',
  },
  {
    title: 'Avg. deal value',
    tooltip: 'Average value of all deals created during the selected period.',
    counter: '$129',
    percent: {
      value: '+9.2%',
      change: 'green',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '$108',
  },
  {
    title: 'Won deals value',
    tooltip: 'Total value of all deals marked as Won during the selected period.',
    counter: '$21,285',
    percent: {
      value: '+14.2%',
      change: 'green',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '$18,623',
  },
  {
    title: 'Lost deals value',
    tooltip: 'Total value of all deals marked as Lost during the selected period.',
    counter: '$4,386',
    percent: {
      value: '+14.2%',
      change: 'red',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '$3,807',
  },
  {
    title: 'Deal win rate',
    tooltip: 'Percentage of deals marked as Won out of the total deals pursued during the selected period.',
    counter: '91.2%',
    percent: {
      value: '-12.8%',
      change: 'red',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '96.7%',
  },
  {
    title: 'Sales cycle length',
    tooltip: 'Average time it took to close won deals, measured from the date each deal was created, during the selected period.',
    counter: '3d 2h',
    percent: {
      value: '+6.1%',
      change: 'green',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '5d 7h',
  },
  {
    title: 'Avg. revenue per agent',
    tooltip: 'Average revenue generated per agent from won deals during the selected period.',
    counter: '$1,150',
    percent: {
      value: '+5.7%',
      change: 'green',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '$3,807',
  },
]

export const reportsDealsStatsHalfEmpty: ReportCardType[] = reportsDealsStats.map(stat => ({
  ...stat,
  counter: '0',
  previously: '0',
})).map(({ percent, ...stat }) => stat)

export const timelineDealsOptions = [
  'New deals',
  'Won deals',
  'Lost deals etc',
]
