
import { defineComponent, ref, computed } from 'vue'
import { getTableData } from '@/services/tableService'
import ReportGraph from '@/components/shared/templates/reports/ReportGraph.vue'
import ReportingDealsAgentPerformanceFilter from '@/components/pages/reporting/deals/ReportingDealsAgentPerformanceFilter.vue'
import ReportingDealsAgentPerformanceTable from '@/components/pages/reporting/deals/ReportingDealsAgentPerformanceTable.vue'
import { useModes } from '@/compositions/modes'
import type { TableHeaders } from '@/definitions/shared/types'
import type { AgentPerfomanceType } from '@/definitions/reporting/deals/types'

export default defineComponent({
  components: {
    ReportGraph,
    ReportingDealsAgentPerformanceFilter,
    ReportingDealsAgentPerformanceTable,
  },
  setup() {
    const { isEmptyHalfMode } = useModes()
    const search = ref('')
    const expand = ref(false)
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Agent', value: 'agent-slot' },
      { text: 'Won deals', value: 'won-deals-slot' },
      { text: 'Won deals value', value: 'won-deals-value-slot' },
      { text: 'Lost deals', value: 'lost-deals-slot' },
      { text: 'Lost deals value', value: 'lost-deals-value-slot' },
      { text: 'New deals', value: 'new-deals-slot' },
      { text: 'New deals value', value: 'new-deals-value-slot' },
    ])
    const tableItems = ref<AgentPerfomanceType[]>(getTableData('agentPerfomance'))
    const filteredTableItems = computed<AgentPerfomanceType[]>(() => {
      return tableItems.value.filter((item) => item.person?.fullName.toLowerCase().includes(search.value.toLowerCase()))
    })

    return {
      isEmptyHalfMode,
      search,
      expand,
      tableHeaders,
      filteredTableItems,
    }
  },
})
