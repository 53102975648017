
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmPerson from '@/components/shared/TmPerson.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { numberToPrice } from '@/services/utils'

export default defineComponent({
  components: {
    TmTable,
    TmPerson,
    TmButton,
  },
  setup() {
    return {
      numberToPrice,
    }
  },
})
