
import { defineComponent } from 'vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'

export default defineComponent({
  components: {
    TmTooltip,
    TopFilter,
  },
})
