
import { defineComponent, computed } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import ReportCards from '@/components/shared/templates/reports/ReportCards.vue'
import ReportingNavigation from '@/components/pages/reporting/ReportingNavigation.vue'
import ReportingTimeline from '@/components/pages/reporting/ReportingTimeline.vue'
import ReportingDealsAgentPerformance from '@/components/pages/reporting/deals/ReportingDealsAgentPerformance.vue'
import ReportingDealsPipeline from '@/components/pages/reporting/deals/ReportingDealsPipeline.vue'
import ReportingDealsRate from '@/components/pages/reporting/deals/ReportingDealsRate.vue'
import ReportingDealsEmpty from '@/components/pages/reporting/deals/ReportingDealsEmpty.vue'
import { reportsFilter } from '@/definitions/shared/filters/filtersList/data'
import { useFilters } from '@/compositions/filters'
import { useModes } from '@/compositions/modes'
import { reportsDealsStats, reportsDealsStatsHalfEmpty, timelineDealsOptions } from '@/definitions/reporting/deals/data'
import { reportsDealsTimelineChartData, reportsDealsTimelineChartDataHalfEmpty } from '@/definitions/shared/charts/reporting/data'

export default defineComponent({
  components: {
    PageContent,
    ReportCards,
    ReportingNavigation,
    ReportingTimeline,
    ReportingDealsAgentPerformance,
    ReportingDealsPipeline,
    ReportingDealsRate,
    ReportingDealsEmpty,
  },
  setup() {
    const { isEmptyMode, isEmptyHalfMode } = useModes()
    const { filters } = useFilters(reportsFilter)

    const stats = computed(() => isEmptyHalfMode.value ? reportsDealsStatsHalfEmpty : reportsDealsStats)
    const timelineChart = computed(() => isEmptyHalfMode.value ? reportsDealsTimelineChartDataHalfEmpty : reportsDealsTimelineChartData)

    return {
      isEmptyMode,
      filters,
      stats,
      timelineChart,
      timelineDealsOptions,
    }
  },
})
