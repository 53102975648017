import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_reporting_deals_agent_performance_filter = _resolveComponent("reporting-deals-agent-performance-filter")!
  const _component_reporting_deals_agent_performance_table = _resolveComponent("reporting-deals-agent-performance-table")!
  const _component_report_graph = _resolveComponent("report-graph")!

  return (_openBlock(), _createBlock(_component_report_graph, {
    size: "xxLarge",
    icon: "tmi-chart",
    title: "Agent performance",
    "no-gap": "",
    "hide-title": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_reporting_deals_agent_performance_filter, {
        search: _ctx.search,
        "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event))
      }, null, 8, ["search"]),
      _createVNode(_component_reporting_deals_agent_performance_table, {
        headers: _ctx.tableHeaders,
        items: _ctx.filteredTableItems,
        "items-name": "agents",
        "hide-actions": ""
      }, null, 8, ["headers", "items"])
    ]),
    _: 1
  }))
}